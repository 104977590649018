

/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

    /**
     * colors
     */
  
    --chinese-violet_30: hsla(304, 14%, 46%, 0.3);
    --chinese-violet: #15504e;
    --sonic-silver: hsl(208, 7%, 46%);
    --old-rose_30: #15504e;
    --ghost-white: hsl(233, 33%, 95%);
    --light-pink: #229e99;
    --light-gray: hsl(0, 0%, 80%);
    --old-rose: #15504e;
    --seashell: hsl(20, 43%, 93%);
    --charcoal: hsl(203, 30%, 26%);
    --white: hsl(0, 0%, 100%);
  
    /**
     * typography
     */
  
    --ff-philosopher: 'Philosopher', sans-serif;
    --ff-poppins: 'Poppins', sans-serif;
  
    --fs-1: 4rem;
    --fs-2: 3.2rem;
    --fs-3: 2.7rem;
    --fs-4: 2.4rem;
    --fs-5: 2.2rem;
    --fs-6: 2rem;
    --fs-7: 1.8rem;
  
    --fw-500: 500;
    --fw-700: 700;
  
    /**
     * spacing
     */
  
    --section-padding: 80px;
  
    /**
     * shadow
     */
  
    --shadow-1: 4px 6px 10px hsla(231, 94%, 7%, 0.06);
    --shadow-2: 2px 0 15px 5px hsla(231, 94%, 7%, 0.06);
    --shadow-3: 3px 3px var(--chinese-violet);
    --shadow-4: 5px 5px var(--chinese-violet);
  
    /**
     * radius
     */
  
    --radius-5: 5px;
    --radius-10: 10px;
  
    /**
     * clip path
     */
  
    --polygon: polygon(100% 29%,100% 100%,19% 99%,0 56%);
  
    /**
     * transition
     */
  
    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);
  
  }
  
  
  
  
  
  /*-----------------------------------*\
    #RESET
  \*-----------------------------------*/
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  li { list-style: none; }
  
  a,
  img,
  span,
  data,
  input,
  button,
  textarea,
  ion-icon { display: block; }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  img { height: auto; }
  
  input,
  button,
  textarea {
    background: none;
    border: none;
    font: inherit;
  }
  
  input,
  textarea { width: 100%; }
  
  button { cursor: pointer; }
  
  address { font-style: normal; }
  
  ion-icon { pointer-events: none; }
  
  html {
    font-family: var(--ff-poppins);
    font-size: 10px;
    scroll-behavior: smooth;
  }
  
  body {
    background-color: var(--white);
    color: var(--sonic-silver);
    font-size: 1.6rem;
    line-height: 1.8;
    height: 100% !important;
  }
  
  :focus-visible { outline-offset: 4px; }
  
  ::selection {
    background-color: var(--old-rose);
    color: var(--white);
  }
  
  ::-webkit-scrollbar { width: 10px; }
  
  ::-webkit-scrollbar-track { background-color: hsl(0, 0%, 98%); }
  
  ::-webkit-scrollbar-thumb { background-color: hsl(0, 0%, 80%); }
  
  ::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 70%); }
  
  
  
  
  
  /*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/
  
  .container { padding-inline: 15px; }
  
  .section { padding-block: var(--section-padding); }
  
  .section:nth-child(odd) { background-color: var(--seashell); }
  
  .section-subtitle {
    color: #AA9563;
    text-transform: uppercase;
    font-weight: var(--fw-700);
    letter-spacing: 4px;
    text-align: center;
  }
  
  .h1,
  .h2,
  .h3,
  .tab-text {
    color: var(--charcoal);
    font-family: var(--ff-philosopher);
    line-height: 1.2;
  }
  
  .h1 { font-size: var(--fs-2); }
  
  .h2 { font-size: var(--fs-3); }
  
  .h3 { font-size: var(--fs-5); }
  
  .section-text { font-size: var(--fs-7); }
  
  .w-100 { width: 100%; }
  
  .has-before,
  .has-after {
    position: relative;
    z-index: 1;
  }
  
  .has-before::before,
  .has-after::after {
    content: "";
    position: absolute;
  }
  
  .section-title { text-align: center !important; }
  
  .grid-list {
    display: grid;
    gap: 25px;
  }
  
  .btn-link {
    color: var(--old-rose);
    font-weight: var(--fw-500);
    display: flex;
    align-items: center;
    gap: 5px;
    transition: var(--transition-1);
  }
  
  .btn-link:is(:hover, :focus) { color: var(--chinese-violet); }
  
  .has-underline {
    position: relative;
    margin-block-end: 60px;
  }
  
  .has-underline .span {
    height: 2px;
    background-color: var(--old-rose_30);
    width: 200px;
    margin-inline: auto;
    margin-block-start: 30px;
  }
  
  .has-underline .has-before::before {
    width: 18px;
    height: 18px;
    top: 2px;
    left: 45%;
    transform: translateY(-50%) rotate(45deg);
    background-color: #15504e;
    box-shadow: 7px -7px #15504e;
  }
  
  .btn {
    padding: 12px 30px;
    transition: var(--transition-1);
  }
  
  .btn-primary {
    background-color: var(--path-green);
    color: var(--white);
  }
  
  .btn-primary:is(:hover, :focus) { background-color: var(--chinese-violet); }
  
  .btn-secondary {
    border: 1px solid var(--old-rose);
    color: var(--old-rose);
  }
  
  .btn-secondary:is(:hover, :focus) {
    background-color: var(--old-rose);
    color: var(--white);
  }
  
  .img-holder {
    aspect-ratio: var(--width) / var(--height);
    background-color: var(--light-gray);
  }
  
  .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #about
  \*-----------------------------------*/

  .about{
    background-color: white !important;
  }
  
  .about .section-text {
    text-align: center;
    margin-block: 8px 25px;
  }
  
  .about-card {
    padding: 25px;
    box-shadow: var(--shadow-2);
    border-radius: var(--radius-5);
    overflow: hidden;
    transition: var(--transition-2);
  }
  
  .about-card::before,
  .about-card::after {
    bottom: 0;
    right: 0;
    clip-path: var(--polygon);
    transition: var(--transition-2);
  }
  
  .about-card::before {
    width: 80px;
    height: 80px;
    background-color: #15504e;
    transform: translateY(60px);
  }
  
  .about-card::after {
    width: 70px;
    height: 70px;
    background-color: var(--chinese-violet_30);
    transform: translateY(65px);
  }
  
  .about-card:is(:hover, :focus-within) { transform: translateY(-10px); }
  
  .about-card:is(:hover, :focus-within)::before,
  .about-card:is(:hover, :focus-within)::after { transform: translateY(0); }
  
  .about-card:is(:hover, :focus-within)::after { transition-delay: 0.1s; }
  
  .about-card .card-icon {
    background-color: #15504e;
    max-width: max-content;
    padding: 10px;
    box-shadow: var(--shadow-3);
    transition: var(--transition-1);
  }
  
  .about-card:is(:hover, :focus) .card-icon { box-shadow: none; }
  
  .about-card .card-title { margin-block: 20px 15px; }
  
  .about-card .btn-link { margin-block-start: 15px; }
  
  .card-text{
    color: #15504e !important;
  }
  
  .section-text{
    color: #15504e !important;
  }
  
  
  
  
  
  
  
  
  /*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/
  
  /**
   * responsive for large than 575px screen
   */
  
  @media (min-width: 575px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-2: 4.5rem;
      --fs-3: 3.5rem;
      --fs-5: 2.5rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    
  
    .section-text { --fs-7: 2rem; }
  
  
  
  
  }
  
  
  
  
  
  /**
   * responsive for large than 768px screen
   */
  
  @media (min-width: 768px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-2: 4.8rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
  
    .grid-list { grid-template-columns: 1fr 1fr; }
  
  
  
  
    /**
     * about
     */
  
    .about :is(.section-title, .section-text) { text-align: left; }
  
    .about-content { grid-column: 1 / 3; }
  
  
  }
  
  
  
  
  
  /**
   * responsive for large than 992px screen
   */
  
  @media (min-width: 992px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-2: 5.4rem;
      --fs-3: 4rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    
  
    .grid-list { grid-template-columns: repeat(3, 1fr); }
  
  
  
  
    /**
     * about
     */
  
    .about .grid-list {
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
    }
  
    .about-content { max-width: 80%; }
  
  
  
   
  
  }
  
  
  
  
  
  /**
   * responsive for large than 1200px screen
   */
  
  @media (min-width: 1200px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-2: 6rem;
      --fs-3: 4.5rem;
      --fs-4: 3rem;
      --fs-5: 3rem;
  
    }
  
  
  
  
  
  
  }