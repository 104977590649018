:root {

    /**
     * colors
     */

    --russian-violet: hsl(269, 36%, 22%);
    --dark-purple: hsl(268, 22%, 15%);
    --eerie-black: hsl(240, 4%, 10%);
    --davys-gray: hsl(0, 0%, 34%);
    --light-gray: hsl(0, 0%, 84%);
    --cultured-1: hsl(0, 0%, 98%);
    --cultured-2: hsl(280, 14%, 96%);
    --cultured-3: hsl(280, 9%, 94%);
    --gainsboro: hsl(0, 1%, 85%);
    --black_10: hsla(0, 0%, 0%, 0.1);
    --black_5: hsla(0, 0%, 0%, 0.05);
    --pumpkin: white;
    --white: hsl(0, 0%, 100%);
    --black: hsl(0, 0%, 0%);

    /**
     * typography
     */

    --ff-poppins: 'Poppins', sans-serif;
    --ff-roboto: 'Roboto', sans-serif;

    --fs-1: 3rem;
    --fs-2: 2rem;
    --fs-3: 1.5rem;
    --fs-4: 1.4rem;
    --fs-5: 1.3rem;

    --fw-900: 900;
    --fw-700: 700;
    --fw-600: 600;
    --fw-500: 500;

    /**
     * spacing
     */

    --section-padding: 50px;

    /**
     * box shadow
     */

    --shadow-1: 0px 0px 20px var(--black_10);
    --shadow-2: 0 0 20px 4px var(--black_5);

    /**
     * border radius
     */

    --radius-6: 6px;
    --radius-10: 10px;

    /**
     * transition
     */

    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
    --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

a,
img,
span,
button,
ion-icon {
    display: block;
}

img {
    height: auto;
}

button {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
}

ion-icon {
    pointer-events: none;
}

address {
    font-style: normal;
}

html {
    font-family: var(--ff-poppins);
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    background-color: var(--white);
    font-size: 1.6rem;
    color: var(--davys-gray);
}

:focus-visible {
    outline-offset: 4px;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
    background-color: hsl(0, 0%, 70%);
}





/*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/

.container {
    padding-inline: 15px;
}

.btn {
    color: var(--color, var(--white));
    font-size: var(--fs-4);
    max-width: var(--width, max-content);
    padding: 10px 20px;
    border-radius: var(--radius-10);
}

.btn-primary,
.btn-secondary:is(:hover, :focus) {
    background-color: var(--pumpkin);
}

.btn-primary:is(:hover, :focus),
.btn-secondary {
    background-color: var(--russian-violet);
}

.btn-outline {
    --color: var(--pumpkin);
    --width: 100%;
    text-align: center;
    font-weight: var(--fw-500);
    border: 1px solid var(--cultured-3);
    justify-content: center;
}

.btn-outline:is(:hover, :focus) {
    background-color: #050608 !important;
    color: white !important;
}

.section {
    padding-block: var(--section-padding);
}

.section-subtitle {
    color: #AA9563;
    font-size: var(--fs-3);
    margin-block-end: 5px;
}

.h1,
.h2,
.h3 {
    font-family: var(--ff-roboto);
}

.h1,
.h2 {
    font-size: var(--fs-1);
}

.h2,
.h3 {
    color: var(--eerie-black);
}

.h3 {
    font-size: var(--fs-2);
}

.section-text,
.card-text {
    font-size: var(--fs-4);
    line-height: 1.8;
}

.btn-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.btn-item-link {
    overflow: hidden;
    border-radius: var(--radius-10);
}

.btn-item-link .img {
    width: 136px;
}

.w-100 {
    width: 100%;
}

.section-title {
    margin-block: 10px 20px;
}

:is(.features, .video) :is(.section-subtitle, .section-title) {
    text-align: center;
}

.img-holder {
    aspect-ratio: var(--width) / var(--height);
    background-color: var(--light-gray);
}

.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition-2);
}






/*-----------------------------------*\
    #video
  \*-----------------------------------*/

.video .section-title {
    margin-block-end: 30px;
}

.video-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}

.video-list>li {
    max-width: 100%;
}

.video-card {
    padding: 15px;
    border-radius: var(--radius-10);
    box-shadow: var(--shadow-2);
    float:'left';
    justify-content: left;
}

.video-card .card-banner {
    border-radius: var(--radius-10);

}

.player{
    width: 100% !important;
}

.video-card:is(:hover, :focus-within) .img-cover {
    transform: scale(1.2);
}

.video-card .card-content {
    padding: 15px;
    padding-block-start: 25px;
}

.video-card :is(.card-meta-list, .card-meta-item) {
    display: flex;
    align-items: center;
}

.video-card .card-meta-list {
    gap: 15px;
    margin-block-end: 15px;
}

.video-card .card-meta-item {
    gap: 6px;
}

.video-card .card-meta-item ion-icon {
    color: var(--pumpkin);
    font-size: var(--fs-5);
}

.video-card .card-meta-text {
    font-size: var(--fs-5);
}

.video-card .h3 {
    margin-block-end: 15px;
}

.video-card .card-title {
    transition: var(--transition-1);
}

.video-card .card-title:is(:hover, :focus) {
    color: #AA9563;
}

.video_button{
    background-color:white;
     width:100%;
    padding:2% 5%;
    justify-content: center;
    color:#15504e;
    border: 1px solid #15504e;
}

.video_button:hover{
   background-color:#15504e;
   color:white;
   border: 1px solid #15504e;
}


/*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/

/**
   * responsive for larger than 575px screen
   */

@media(min-width: 575px) {

    /**
     * REUSED STYLE
     */

    .container {
        max-width: 540px;
        width: 100%;
        margin-inline: auto;
    }




}





/**
   * responsive for larger than 768px screen
   */

@media (min-width: 768px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * typography
       */

        --fs-1: 4.5rem;

    }



    /**
     * REUSED STYLE
     */

    .container {
        max-width: 720px;
    }

    .h2 {
        --fs-1: 3.5rem;
    }

    .btn {
        font-size: unset;
        padding: 5px 30px;
        color: white;
        text-align: center !important;
    }

    .btn-list {
        gap: 20px;
    }

    .btn-item-link .img {
        width: 150px;
    }






    /**
     * video
     */

    .video-list {
        gap: 20px;
    }

    .video-list>li {
        max-width: calc(50% - 10px);
    }

}





/**
   * responsive for larger than 992px screen
   */

@media (min-width: 992px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * spacing
       */

        --section-padding: 100px;

    }



    /**
     * REUSED STYLE
     */

    .container {
        max-width: 960px;
    }

    .btn-item-link .img {
        width: 185px;
    }



    /**
     * video
     */

    .video-list>li {
        max-width: calc(33.33% - 13.33px);
    }



    /**
     * FOOTER
     */

    .footer-top .container {
        grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr;
        gap: 50px;
    }

}





/**
   * responsive for larger than 1200px screen
   */

@media (min-width: 1200px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * typography
       */

        --fs-1: 5rem;

    }



    /**
     * REUSED STYLE
     */

    .container {
        max-width: 1180px;
    }

    .section-text {
        --fs-4: 1.5rem;
    }

    .h2 {
        --fs-1: 4rem;
    }

    .h3 {
        --fs-2: 2.2rem;
    }




    /**
     * video
     */

    .video-list {
        gap: 25px;
    }

    .video-list>li {
        max-width: calc(33.33% - 16.66px);
    }



}