/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

    /**
     * colors
     */

    --green-blue-crayola: hsl(202, 75%, 47%);
    --prussian-blue:  #050608;
    --eerie-black: hsl(210, 11%, 15%);
    --dark-orange: #15504e;
    --alice-blue: hsl(203, 100%, 97%);
    --light-gray: hsl(0, 0%, 80%);
    --indigo-dye:  #050608;
    --cultured-1: hsl(0, 0%, 94%);
    --cultured-2: hsl(0, 0%, 95%);
    --cultured-3: hsl(0, 0%, 95%);
    --plutinum: hsl(0, 0%, 91%);
    --black_60: hsla(0, 0%, 0%, 0.6);
    --white: hsl(0, 0%, 100%);
    --black: hsl(0, 0%, 0%);
    --onyx: hsl(0, 0%, 24%);

    /**
     * typography
     */

    --ff-oswald: 'Oswald', sans-serif;
    --ff-rubik: 'Rubik', sans-serif;

    --fs-1: 6rem;
    --fs-2: 5rem;
    --fs-3: 4rem;
    --fs-4: 3rem;
    --fs-5: 2.2rem;
    --fs-6: 2rem;
    --fs-7: 1.8rem;
    --fs-8: 1.5rem;
    --fs-9: 1.4rem;
    --fs-10: 1.2rem;

    --fw-500: 500;
    --fw-600: 600;
    --fw-700: 700;
    --fw-800: 800;

    /**
     * spacing
     */

    --section-padding: 50px;

    /**
     * shadow
     */

    --shadow-1: 2px 0 50px hsla(0, 0%, 0%, 0.2);
    --shadow-2: 0px 40px 60px hsla(202, 75%, 47%, 0.7);
    --shadow-3: 0px 0px 60px hsla(202, 75%, 47%, 0.5);

    /**
     * transition
     */

    --transition: 0.25s ease;
    --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
    --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

a,
img,
time,
span,
input,
button,
ion-icon {
    display: block;
}

img {
    height: auto;
}

input,
button {
    background: none;
    border: none;
    font: inherit;
}

input {
    width: 100%;
}

button {
    cursor: pointer;
}

ion-icon {
    pointer-events: none;
}

html {
    font-family: var(--ff-rubik);
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    background-color: var(--white);
    color: var(--onyx);
    font-size: 1.6rem;
    line-height: 1.7;
}

:focus-visible {
    outline-offset: 4px;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: var(--cultured-2);
}

::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 80%);
    border: 2px solid var(--cultured-2);
}

::-webkit-scrollbar-thumb:hover {
    background-color: hsl(0, 0%, 70%);
}





/*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/

.container {
    padding-inline: 15px;
}

.section {
    padding-block: var(--section-padding);
}

.h1,
.h2,
.h3 {
    font-family: var(--ff-oswald);
    line-height: 1.2;
    font-weight: var(--fw-600);
}

.h1 {
    font-size: var(--fs-3);
    text-transform: uppercase;
}

.h2,
.h3 {
    color: var(--prussian-blue);
}

.h2 {
    font-size: var(--fs-4);
}

.h3 {
    font-size: var(--fs-5);
}

.img-holder {
    background-color: var(--light-gray);
    aspect-ratio: var(--width) / var(--height);
}

.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section-subtitle {
    color: var(--dark-orange);
    text-transform: uppercase;
    font-size: var(--fs-6);
    font-family: var(--ff-oswald);
    font-weight: var(--fw-600);
    letter-spacing: 3px;
    margin-block-end: 10px;
}

.section-text {
    line-height: 1.4;
}

.btn {
    position: relative;
    color: var(--white);
    background-color: var(--dark-orange);
    max-width: max-content;
    font-size: var(--fs-9);
    letter-spacing: 1px;
    padding-block: 10px;
    padding-inline: 15px 50px;
}







.grid-list {
    display: grid;
    gap: 30px;
}




.w-100 {
    width: 100%;
}







/*-----------------------------------*\
    #BLOG
  \*-----------------------------------*/

.blog :is(.section-subtitle, .section-title, .section-text) {
    text-align: center;
}

.blog .section-text {
    margin-block: 20px 50px;
}

.blog-card{
    background-color: white;
    box-shadow: 2px 2px 2px 2px grey;
}



.blog-card .card-content {
    position: relative;
}



.blog-card .card-meta {
    background-color: var(--prussian-blue);
    color: var(--white);
    text-align: center;
    font-size: var(--fs-5);
    font-family: var(--ff-oswald);
    font-weight: var(--fw-600);
    line-height: 1.5;
    max-width: max-content;
    margin-inline: auto 20px;
    margin-block: -80px 20px;
    padding: 20px 25px;
    box-shadow: -8px 8px 0 var(--dark-orange);
}

.blog-card .card-meta .span {
    color: var(--dark-orange);
    font-size: var(--fs-3);
    font-weight: var(--fw-700);
    line-height: 0.75;
}

.blog-card .card-text {
    margin-block: 15px;
}

















/*-----------------------------------*\
    #MEDIA QUERISE
  \*-----------------------------------*/

/**
   * responsive for larger than 540px screen
   */

@media (min-width: 540px) {}





/**
   * responsive for larger than 640px screen
   */

@media (min-width: 640px) {}





/**
   * responsive for larger than 768px screen
   */

@media (min-width: 768px) {}





/**
   * responsive for larger than 992px screen
   */

@media (min-width: 992px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * typography
       */

        --fs-4: 4.5rem;

        /**
       * spacing
       */

        --section-padding: 120px;

    }




    /**
     * BLOG
     */

    .blog-list {
        grid-template-columns: 1fr 1fr;
    }




}





/**
   * responsive for larger than 1200px screen
   */

@media (min-width: 1200px) {}