/*-----------------------------------*\#AA9563
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

    /**
     * COLORS
     */

    --path-green: white;
    --quick-silver: hsla(0, 0%, 65%, 1);
    --davys-grey: hsla(30, 3%, 34%, 1);
    --smoky-black-1: hsla(40, 12%, 5%, 1);
    --smoky-black-2: hsla(30, 8%, 5%, 1);
    --smoky-black-3: hsla(0, 3%, 7%, 1);
    --eerie-black-1: hsla(210, 4%, 9%, 1);
    --eerie-black-2: hsla(210, 4%, 11%, 1);
    --eerie-black-3: hsla(180, 2%, 8%, 1);
    --eerie-black-4: hsla(0, 0%, 13%, 1);
    --white: hsla(0, 0%, 100%, 1);
    --white-alpha-20: hsla(0, 0%, 100%, 0.2);
    --white-alpha-10: hsla(0, 0%, 100%, 0.1);
    --black: hsla(0, 0%, 0%, 1);
    --black-alpha-80: hsla(0, 0%, 0%, 0.8);
    --black-alpha-15: hsla(0, 0%, 0%, 0.15);

    /**
     * GRADIENT COLOR
     */

    --loading-text-gradient: linear-gradient(90deg, transparent 0% 16.66%, var(--smoky-black-3) 33.33% 50%, transparent 66.66% 75%);
    --gradient-1: linear-gradient(to top, hsla(0, 0%, 0%, 0.9), hsla(0, 0%, 0%, 0.7), transparent);

    /**
     * TYPOGRAPHY
     */

    /* font-family */
    --fontFamily-forum: 'Forum', cursive;
    --fontFamily-dm_sans: 'DM Sans', sans-serif;

    /* font-size */
    --fontSize-display-1: calc(1.3rem + 6.7vw);
    --fontSize-headline-1: calc(2rem + 2.5vw);
    --fontSize-headline-2: calc(1.3rem + 2.4vw);
    --fontSize-title-1: calc(1.6rem + 1.2vw);
    --fontSize-title-2: 2.2rem;
    --fontSize-title-3: 2.1rem;
    --fontSize-title-4: calc(1.6rem + 1.2vw);
    --fontSize-body-1: 2.4rem;
    --fontSize-body-2: 2.5rem;
    --fontSize-body-3: 1.8rem;
    --fontSize-body-4: 1.6rem;
    --fontSize-label-1: 1.4rem;
    --fontSize-label-2: 1.2rem;

    /* font-weight */
    --weight-regular: 400;
    --weight-bold: 700;

    /* line-height */
    --lineHeight-1: 1em;
    --lineHeight-2: 1.2em;
    --lineHeight-3: 1.5em;
    --lineHeight-4: 1.6em;
    --lineHeight-5: 1.85em;
    --lineHeight-6: 1.4em;

    /* letter-spacing */
    --letterSpacing-1: 0.15em;
    --letterSpacing-2: 0.4em;
    --letterSpacing-3: 0.2em;
    --letterSpacing-4: 0.3em;
    --letterSpacing-5: 3px;

    /**
     * SPACING
     */

    --section-space: 70px;

    /**
     * SHADOW
     */

    --shadow-1: 0px 0px 25px 0px hsla(0, 0%, 0%, 0.25);

    /**
     * BORDER RADIUS
     */

    --radius-24: 24px;
    --radius-circle: 50%;

    /**
     * TRANSITION
     */

    --transition-1: 250ms ease;
    --transition-2: 500ms ease;
    --transition-3: 1000ms ease;

}





/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style: none;
}

a,
img,
data,
span,
input,
button,
select,
ion-icon,
textarea {
    display: block;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    height: auto;
}

input,
button,
select,
textarea {
    background: none;
    border: none;
    font: inherit;
}

input,
select,
textarea {
    width: 100%;
    outline: none;
}

button {
    cursor: pointer;
}

address {
    font-style: normal;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    background-color: var(--eerie-black-1);
    color: var(--white);
    font-family: var(--fontFamily-dm_sans);
    font-size: var(--fontSize-body-4);
    font-weight: var(--weight-regular);
    line-height: var(--lineHeight-5);
    overflow-x: hidden;
    height: 300vh;
}

body.loaded {
    overflow: overlay;
}

body.nav-active {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: var(--path-green);
}





/*-----------------------------------*\
    #TYPOGRAPHY
  \*-----------------------------------*/

.display-1,
.headline-1,
.headline-2,
.title-1,
.title-2,
.title-3,
.title-4 {
    color: var(--white);
    font-family: var(--fontFamily-forum);
    font-weight: var(--weight-regular);
    line-height: var(--lineHeight-2);
}

.display-1 {
    font-size: var(--fontSize-display-1);
    line-height: var(--lineHeight-1);
}

.headline-1 {
    font-size: var(--fontSize-headline-1);
}

.headline-2 {
    font-size: var(--fontSize-headline-2);
    line-height: var(--lineHeight-6);
}

.title-1 {
    font-size: var(--fontSize-title-1);
}

.title-2 {
    font-size: var(--fontSize-title-2);
}

.title-3 {
    font-size: var(--fontSize-title-3);
}

.title-4 {
    font-size: var(--fontSize-title-4);
}

.body-1 {
    font-size: var(--fontSize-body-1);
    line-height: var(--lineHeight-6);
}

.body-2 {
    font-size: var(--fontSize-body-2);
    line-height: var(--lineHeight-4);
}

.body-3 {
    font-size: var(--fontSize-body-3);
}

.body-4 {
    font-size: var(--fontSize-body-4);
}

.label-1 {
    font-size: var(--fontSize-label-1);
}

.label-2 {
    font-size: var(--fontSize-label-2);
}





/*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/

.container {
    padding-inline: 16px;
}

.separator {
    width: 8px;
    height: 8px;
    border: 1px solid var(--path-green);
    transform: rotate(45deg);
}

.contact-label {
    font-weight: var(--weight-bold);
}

.contact-number {
    color: var(--path-green);
    max-width: max-content;
    margin-inline: auto;
}

.hover-underline {
    position: relative;
    max-width: max-content;
}

.hover-underline::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    border-block: 1px solid var(--path-green);
    transform: scaleX(0.2);
    opacity: 0;
    transition: var(--transition-2);
}

.hover-underline:is(:hover, :focus-visible)::after {
    transform: scaleX(1);
    opacity: 1;
}

.contact-number::after {
    bottom: -5px;
}

.text-center {
    text-align: center;
}

.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section-subtitle {
    position: relative;
    color:  #AA9563 !important;
    font-weight: var(--weight-bold);
    text-transform: uppercase;
    letter-spacing: var(--letterSpacing-2);
    margin-block-end: 12px;
}

.section-subtitle::after {
    content: url('images/separator.svg');
    display: block;
    width: 100px;
    margin-inline: auto;
    margin-block-start: 5px;
    color:white !important;
}

.btn {
    position: relative;
    color: white;
    font-size: var(--fontSize-label-2);
    font-weight: var(--weight-bold);
    text-transform: uppercase;
    letter-spacing: var(--letterSpacing-5);
    max-width: max-content;
    border:  #050608;
    padding: 12px 45px;
    background-color: var(--path-green) !important;
    overflow: hidden;
    z-index: 1;
}



.btn .text {
    transition: var(--transition-1);
}

.btn .text-2 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    min-width: max-content;
    color: var(--smoky-black-1);
}

.btn:is(:hover, :focus-visible)::before {
    bottom: -50%;
}



.btn:is(:hover, :focus-visible) .text-2 {
    top: 50%;
   
}

.btn-secondary {
    background-color: var(--path-green);
    color: var(--black);
}

.btn-secondary::before {
    background-color: var(--smoky-black-1);
}

.btn-secondary .text-2 {
    color: black;
}

.has-before,
.has-after {
    position: relative;
    z-index: 1;
}

.has-before::before,
.has-after::after {
    content: "";
    position: absolute;
}

.section {
    position: relative;
    padding-block: var(--section-space);
    overflow: hidden;
    z-index: 1;
}

.bg-black-10 {
    background-color: var(--smoky-black-2);
}

.grid-list {
    display: grid;
    gap: 40px;
}

.hover\:shine {
    position: relative;
}

.hover\:shine::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: linear-gradient(to right, transparent 0%, #fff6 100%);
    transform: skewX(-0.08turn) translateX(-180%);
}

.hover\:shine:is(:hover, :focus-within)::after {
    transform: skewX(-0.08turn) translateX(275%);
    transition: var(--transition-3);
}

.img-holder {
    aspect-ratio: var(--width) / var(--height);
    overflow: hidden;
    background-color: var(--eerie-black-4);
}

.btn-text {
    color: var(--path-green);
    padding-block-end: 4px;
    margin-inline: auto;
    text-transform: uppercase;
    letter-spacing: var(--letterSpacing-3);
    font-weight: var(--weight-bold);
    transition: var(--transition-1);
}

.btn-text:is(:hover, :focus-visible) {
    color: var(--white);
}

.shape {
    display: none;
    position: absolute;
    max-width: max-content;
    z-index: -1;
}

.w-100 {
    width: 100%;
}

.move-anim {
    animation: move 5s linear infinite;
}

@keyframes move {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(30px);
    }
}

.has-bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.input-field {
    background-color: var(--eerie-black-2);
    color: var(--white);
    height: 56px;
    padding: 10px 20px;
    border: 1px solid var(--white-alpha-10);
    margin-block-end: 20px;
    outline: none;
    transition: border-color var(--transition-2);
}

.input-field::placeholder {
    color: inherit;
}

.input-field:focus {
    border-color: var(--path-green);
}

.icon-wrapper {
    position: relative;
    margin-block-end: 20px;
}

.icon-wrapper .input-field {
    margin-block-end: 0;
    padding-inline-start: 40px;
    appearance: none;
    cursor: pointer;
}

.icon-wrapper ion-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    --ionicon-stroke-width: 50px;
    pointer-events: none;
}

.icon-wrapper ion-icon:first-child {
    left: 15px;
}

.icon-wrapper ion-icon:last-child {
    right: 10px;
}





/*-----------------------------------*\
    #PRELOAD
  \*-----------------------------------*/

.preload {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--path-green);
    z-index: 10;
    display: grid;
    place-content: center;
    justify-items: center;
    transition: var(--transition-2);
}

.preload>* {
    transition: var(--transition-1);
}

.preload.loaded>* {
    opacity: 0;
}

.preload.loaded {
    transition-delay: 250ms;
    transform: translateY(100%);
}

.circle {
    width: 112px;
    height: 112px;
    border-radius: var(--radius-circle);
    border: 3px solid var(--white);
    border-block-start-color: var(--smoky-black-3);
    margin-block-end: 45px;
    animation: rotate360 1s linear infinite;
}

@keyframes rotate360 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(1turn);
    }
}

.preload .text {
    background-image: var(--loading-text-gradient);
    background-size: 500%;
    font-size: calc(2rem + 3vw);
    font-weight: var(--weight-bold);
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 16px;
    padding-inline-start: 16px;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 0.5px var(--eerie-black-3);
    animation: loadingText linear 2s infinite;
}

@keyframes loadingText {
    0% {
        background-position: 100%;
    }

    100% {
        background-position: 0%;
    }
}








/*-----------------------------------*\
    #HERO
  \*-----------------------------------*/

.hero .slider-btn {
    display: none;
}

.hero {
    position: relative;
    padding-block: 120px;
    height: 100vh !important;
    z-index: 1;

}

.hero .slider-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
    padding-block-start: 100px;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition-3);
    z-index: 1;
}

.hero .slider-item.active {
    opacity: 1;
    visibility: visible;
}

.hero .slider-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.15);
    pointer-events: none;
    user-select: none;
    z-index: -1;
}

.hero .slider-item.active .slider-bg {
    animation: smoothScale 7s linear forwards;
}

.text{
    color: black !important;
}

@keyframes smoothScale {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.15);
    }
}

.hero .section-subtitle::after {
    margin-block: 14px 20px;
    color:white !important;
}

.hero-text {
    margin-block: 10px 40px;
}

.hero .btn {
    margin-inline: auto;
}

.hero-btn {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 2;
    background-color: var(--path-green);
    width: 110px;
    height: 110px;
    padding: 12px;
    transform: scale(0.6);
}

.hero-btn img {
    margin-inline: auto;
    margin-block-end: 6px;
}

.hero-btn .span {
    color: black !important;
    font-weight: var(--weight-bold);
    text-transform: uppercase;
    letter-spacing: var(--letterSpacing-1);
    line-height: var(--lineHeight-3);
}

.hero-btn::after {
    inset: 0;
    border: 1px solid var(--path-green);
    animation: rotate360 15s linear infinite;
}

.slider-reveal {
    transform: translateY(30px);
    opacity: 0;
}

.hero .slider-item.active .slider-reveal {
    animation: sliderReveal 1s ease forwards;
}

@keyframes sliderReveal {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.hero .slider-item.active .section-subtitle {
    animation-delay: 500ms;
     color:white !important;
}

.hero .slider-item.active .hero-title {
    animation-delay: 1000ms;
    color: var(--path-green);
}

.hero .slider-item.active .hero-text {
    animation-delay: 1.5s;
    color: white !important;
}

.hero .slider-item.active .btn {
    animation-delay: 2s;
}

.cover{
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
    background: rgba(0, 0, 0,0.65);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}





/*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/

/**
   * responsive for larger than 575px screen
   */

@media (min-width: 575px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * typography
       */

        --fontSize-body-2: 2rem;

    }



    /**
     * REUSED STYLE
     */

    :is(.service, .about) .section-text {
        max-width: 420px;
        margin-inline: auto;
    }

    .contact-number {
        --fontSize-body-1: 3rem;
    }




    /**
     * HERO
     */

    .hero-btn {
        transform: scale(0.7);
    }

}





/**
   * responsive for larger than 768px screen
   */

@media (min-width: 768px) {

    /**
     * REUSED STYLE
     */

    .grid-list {
        grid-template-columns: 1fr 1fr;
    }

    :is(.service, .event) .container {
        max-width: 820px;
    }

    :is(.service, .event) .grid-list li:last-child {
        grid-column: 1 / 3;
        width: calc(50% - 20px);
        margin-inline: auto;
    }

    /**
     * HERO
     */

    .hero .slider-btn {
        display: grid;
        position: absolute;
        z-index: 1;
        color: var(--path-green);
        font-size: 2.4rem;
        border: 1px solid var(--path-green);
        width: 45px;
        height: 45px;
        place-items: center;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        transition: var(--transition-1);
    }

    .hero .slider-btn ion-icon {
        transform: rotate(-45deg);
    }

    .hero .slider-btn.prev {
        left: 30px;
    }

    .hero .slider-btn.next {
        right: 30px;
    }

    .hero .slider-btn:is(:hover, :focus-visible) {
        background-color: var(--path-green);
        color: var(--black);
    }


}





/**
   * responsive for larger than 992px screen
   */

@media (min-width: 992px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * spacing
       */

        --section-space: 100px;

    }



    /**
     * REUSED STYLE
     */

    :is(.service, .event) .container {
        max-width: unset;
    }

    :is(.service, .event) .grid-list {
        grid-template-columns: repeat(3, 1fr);
    }

    :is(.service, .event) .grid-list li:last-child {
        grid-column: auto;
        width: 100%;
    }

}





/**
   * responsive for larger than 1200px screen
   */

@media (min-width: 1200px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * typography
       */

        --fontSize-title-2: 2.5rem;

    }



    /**
     * REUSED STYLE
     */

    .container,
    :is(.service, .event) .container {
        max-width: 1200px;
        width: 100%;
        margin-inline: auto;
    }




    /**
     * HERO
     */

    .hero {
        height: 880px;
    }

    .hero-btn {
        bottom: 50px;
        right: 50px;
        transform: scale(1);
    }

}





/**
   * responsive for larger than 1400px screen
   */

@media (min-width: 1400px) {}