:root {

    
  
    --gold-crayola: hsl(38, 61%, 73%);
    --quick-silver: hsla(0, 0%, 65%, 1);
    --davys-grey: hsla(30, 3%, 34%, 1);
    --smoky-black-1: hsla(40, 12%, 5%, 1);
    --smoky-black-2: hsla(30, 8%, 5%, 1);
    --smoky-black-3: hsla(0, 3%, 7%, 1);
    --eerie-black-1: hsla(210, 4%, 9%, 1);
    --eerie-black-2: hsla(210, 4%, 11%, 1);
    --eerie-black-3: hsla(180, 2%, 8%, 1);
    --eerie-black-4: hsla(0, 0%, 13%, 1);
    --white: hsla(0, 0%, 100%, 1);
    --white-alpha-20: hsla(0, 0%, 100%, 0.2);
    --white-alpha-10: hsla(0, 0%, 100%, 0.1);
    --black: hsla(0, 0%, 0%, 1);
    --black-alpha-80: hsla(0, 0%, 0%, 0.8);
    --black-alpha-15: hsla(0, 0%, 0%, 0.15);
  
   
    --loading-text-gradient: linear-gradient(90deg, transparent 0% 16.66%, var(--smoky-black-3) 33.33% 50%, transparent 66.66% 75%);
    --gradient-1: linear-gradient(to top, hsla(0, 0%, 0%, 0.9), hsla(0, 0%, 0%, 0.7), transparent);
  
    --fontFamily-forum: 'Forum', cursive;
    --fontFamily-dm_sans: 'DM Sans', sans-serif;
  

    --fontSize-display-1: calc(1.3rem + 3.0vw);
    --fontSize-headline-1: calc(2rem + 2.5vw);
    --fontSize-headline-2: calc(1.3rem + 2.4vw);
    --fontSize-title-1: calc(1.6rem + 1.2vw);
    --fontSize-title-2: 2.2rem;
    --fontSize-title-3: 2.1rem;
    --fontSize-title-4: calc(1.6rem + 1.2vw);
    --fontSize-body-1: 2.4rem;
    --fontSize-body-2: 1.6rem;
    --fontSize-body-3: 1.8rem;
    --fontSize-body-4: 1.6rem;
    --fontSize-label-1: 1.4rem;
    --fontSize-label-2: 1.2rem;
  

    --weight-regular: 400;
    --weight-bold: 700;
  
    --lineHeight-1: 1em;
    --lineHeight-2: 1.2em;
    --lineHeight-3: 1.5em;
    --lineHeight-4: 1.6em;
    --lineHeight-5: 1.85em;
    --lineHeight-6: 1.4em;
  
   
    --letterSpacing-1: 0.15em;
    --letterSpacing-2: 0.4em;
    --letterSpacing-3: 0.2em;
    --letterSpacing-4: 0.3em;
    --letterSpacing-5: 3px;
  
  
    --banner-space: 70px;
  
  
    --shadow-1: 0px 0px 25px 0px hsla(0, 0%, 0%, 0.25);
  
  
  
    --radius-24: 24px;
    --radius-circle: 50%;
  
  
    --transition-1: 250ms ease;
    --transition-2: 500ms ease;
    --transition-3: 1000ms ease;
  
  }
  
  
  
  
  
  
  .banner .slider-btn {
    display: none;
  }
  

  

  .banner {
    position: relative;
    padding-block: 200px;
    min-height: 60vh;
    overflow: hidden;
    z-index: 1;
    width: 100%;
  
  }
  
  .overlay {
    background-color: black !important;
    z-index: 2 !important;
  }
  
  .banner-subtitle {
    color: white;
  }
  
  
  .banner .slider-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
    padding-block-start: 100px;
    opacity: 0;
    visibility: hidden;
    /* transition: var(--transition-3); */
    z-index: 1;
    color: black !important;
  }
  
  .banner .slider-item.active {
    opacity: 2;
    visibility: visible;
  }
  
  .banner .slider-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.15);
    pointer-events: none;
    user-select: none;
    z-index: -1;
  }
  
  .banner .slider-item.active .slider-bg {
    animation: smoothScale 7s linear forwards;
  }
  
  @keyframes smoothScale {
    0% {
      transform: scale(1);
    }
  
    100% {
      transform: scale(1.15);
    }
  }
  
  .banner .banner-subtitle::after {
    margin-block: 14px 20px;
    color: white !important;
  }
  
  .banner-text {
    margin-block: 10px 40px;
  }
  
  .banner .btn {
    margin-inline: auto;
  }
  
  .display-1{
    font-family: Roboto;
  }
  
  .banner-btn {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 2;
    background-color: #cfb042;
    width: 110px;
    height: 110px;
    padding: 12px;
    transform: scale(0.6);
  }
  
  .banner-btn img {
    margin-inline: auto;
    margin-block-end: 6px;
  }

  .img-cover{
    height: 100% !important;
  }
  
  .banner-btn .span {
    color: var(--black);
    font-weight: var(--weight-bold);
    text-transform: uppercase;
    letter-spacing: var(--letterSpacing-1);
    line-height: var(--lineHeight-3);
  }
  
  .banner-btn::after {
    inset: 0;
    border: 1px solid #cfb042;
    animation: rotate360 15s linear infinite;
  }
  
  .slider-reveal {
    transform: translateY(30px);
    opacity: 0;
  }
  
  .banner .slider-item.active .slider-reveal {
    animation: sliderReveal 1s ease forwards;
  }
  
  @keyframes sliderReveal {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .banner .slider-item.active .banner-subtitle {
    animation-delay: 500ms;
    color: white !important;
    font-size: 16px !important;
  }
  
  .banner .slider-item.active .banner-title {
    animation-delay: 1000ms;
  }
  
  .banner .slider-item.active .banner-text {
    animation-delay: 1.5s;
    color: white !important;
    font-size: 20px !important;
  }
  
  .banner .slider-item.active .btn {
    animation-delay: 2s;
  }
  
  
  
  .banner-btn {
    transform: scale(0.7);
  }
  
  
 
  
  .banner .slider-btn {
    display: grid;
    position: absolute;
    z-index: 1;
    color: #cfb042;
    font-size: 2.4rem;
    border: 1px solid #cfb042;
    width: 45px;
    height: 45px;
    place-items: center;
    top: 50%;
    transform: translateY(-50%) rotate(45deg); 
     transition: var(--transition-1);
  }
  
  .banner .slider-btn ion-icon {
    transform: rotate(-45deg);
  }
  
  .banner .slider-btn.prev {
    left: 30px;
    border: none;
    color: white;
  }
  
  .banner .slider-btn.next {
    right: 30px;
    border: none;
    color: white;
   
  }
  
  .banner .slider-btn:is(:hover, :focus-visible) {
    /* background-color: #cfb042; */
    color: #4d7386;
  }
  
  
  .banner {
    height: 420px;
  }
  
  .banner-btn {
    bottom: 50px;
    right: 50px;
    transform: scale(1);
  }
  
  p {
    color: white !important;
  }
  
  .view-btn {
    color: white !important;
    border: 1px solid wheat !important;
    background-color: none;
    padding: 1% 0%;
    margin: 0px;
    width: 100% !important;
    font-size: 14px !important;
  }
  
  .view-btn:hover {
    background-color: wheat !important;
    color: black;
  
  }
  

  .overlay-list {
    padding-block: 60px 30px;
    padding-inline: 25px;
    display: grid;
    gap: 30px;
    border-radius: var(--radius-12);
    margin-block-start: -60px;
    background-color: var(--black);
    box-shadow: var(--shadow-1);
  
  
  }
  
  .overlay-card {
    text-align: center;
  }
  
  .overlay-card .card-icon,
  .btn-circle {
    max-width: max-content;
    margin-inline: auto;
  }
  
  .overlay-card .card-icon {
    margin-block-end: 25px;
  }
  
  .overlay-card .card-text {
    margin-block: 20px 15px;
  }
  
  .overlay-card .btn-circle {
    color: var(--verdigris);
    font-size: 2rem;
    padding: 18px;
    border-radius: var(--radius-circle);
    box-shadow: var(--shadow-2);
    transition: var(--transition-1);
  }
  
  .overlay-card .btn-circle:is(:hover, :focus-visible) {
    background-color: var(--verdigris);
    color: var(--white);
  }
  
  
  @media (min-width: 768px) {
  
    .overlay-list {
      grid-template-columns: 1fr 1fr;
    }
  
  
  }
  
  @media (min-width: 992px) {
    .overlay-list {
      grid-template-columns: repeat(4, 1fr);
    }
  }