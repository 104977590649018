/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
     * colors
     */

  --rich-black-fogra-39_50: hsla(0, 0%, 5%, 0.5);
  --rich-black-fogra-39: hsl(0, 0%, 5%);
  --indian-yellow_10: hsla(36, 61%, 58%, 0.1);
  --indian-yellow: #15504e;
  --harvest-gold: #15504e;
  --eerie-black-1: hsl(0, 0%, 14%);
  --eerie-black-2: hsl(0, 0%, 12%);
  --eerie-black-2_85: hsla(0, 0%, 12%, 0.85);
  --eerie-black-3: hsl(0, 0%, 8%);
  --sonic-silver: hsl(0, 0%, 44%);
  --davys-gray: hsl(210, 9%, 31%);
  --light-gray: hsl(0, 0%, 80%);
  --platinum: hsl(0, 0%, 91%);
  --black_30: hsla(0, 0%, 0%, 0.3);
  --white_10: hsla(0, 0%, 100%, 0.1);
  --white_30: hsla(0, 0%, 100%, 0.3);
  --white_50: hsla(0, 0%, 100%, 0.5);
  --white: hsl(100, 90%, 0%);
  --jet: hsl(0, 0%, 21%);

  /**
     * typography
     */

  --ff-oswald: 'Oswald', sans-serif;
  --ff-rubik: 'Rubik', sans-serif;

  --fs-40: 4rem;
  --fs-30: 3rem;
  --fs-24: 2.4rem;
  --fs-18: 1.8rem;
  --fs-14: 1.4rem;
  --fs-13: 1.3rem;

  --fw-300: 300;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /**
     * spacing
     */

  --section-padding: 80px;

  /**
     * shadow
     */

  --shadow-1: 10px 0 60px hsla(0, 0%, 15%, 0.07);
  --shadow-2: 10px 0 60px hsla(0, 0%, 15%, 0.1);

  /**
     * radius
     */

  --radius-5: 5px;
  --radius-8: 8px;

  /**
     * transition
     */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a,
img,
span,
data,
input,
select,
button,
textarea,
ion-icon {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
}

input,
select,
button,
textarea {
  background: none;
  border: none;
  font: inherit;
}

input,
select,
textarea {
  width: 100%;
}

button {
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}

html {
  font-family: var(--ff-rubik);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  color: var(--sonic-silver);
  font-size: 1.6rem;
  line-height: 2;
}

:focus-visible {
  outline-offset: 4px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 70%);
}





/*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/

.container {
  padding-inline: 15px;
}

.section {
  padding-block: var(--section-padding);
}

.has-before,
.has-after {
  position: relative;
  z-index: 1;
}

.has-before::before,
.has-after::after {
  position: absolute;
  content: "";
}

.has-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.h1,
.h2,
.h3 {
  font-family: var(--ff-oswald);
  line-height: 1.3;
}

.h1,
.h2 {
  text-transform: uppercase;
}

.h1,
.h3 {
  font-weight: var(--fw-600);
}

.h1 {
  color: var(--white);
  font-size: var(--fs-40);
}

.h2,
.h3 {
  color: #15504e;
}

.h2 {
  font-size: var(--fs-30);
}

.h3 {
  font-size: var(--fs-24);
}

.btn {
  color: var(--white);
  background-color: var(--indian-yellow);
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: max-content;
  padding: 10px 25px;
  font-family: var(--ff-oswald);
  font-size: var(--fs-14);
  font-weight: var(--fw-600);
  text-transform: uppercase;
  border-radius: var(--radius-5);
  overflow: hidden;
}






.grid-list {
  display: grid;
  gap: 30px;
}

.img-holder {
  aspect-ratio: var(--width) / var(--height);
  background-color: var(--light-gray);
  overflow: hidden;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}










/*-----------------------------------*\
    #SERVICE
  \*-----------------------------------*/

.service {
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 10vh;
}

.service .section-text {
  margin-block: 15px 55px;
}

.service-card {
  background-color: var(--white);
  padding: 60px 25px;
  box-shadow: var(--shadow-1);
  border-radius: var(--radius-8);
  height: 40vh !important;
}

.page-service-card {
  background-color: var(--white);
  padding: 60px 25px;
  box-shadow: var(--shadow-1);
  border-radius: var(--radius-8);
  height: 60vh !important;
}

.service-card .card-icon {
  color:#15504e !important;
  font-size: 70px;
  line-height: 1;
}

.service-card .card-title {
  margin-block: 15px 12px;
  transition: var(--transition-1);
}

.service-card .card-title:is(:hover, :focus) {
  color: #15504e;
}

.service-card .card-text {
  margin-block-end: 30px;
  color: #15504e !important;
}

.service-card .card-btn {
  color: #15504e !important;
  background-color: var(--white);
  max-width: max-content;
  margin-inline: auto;
  font-size: 20px;
  padding: 15px;
  box-shadow: var(--shadow-2);
  border-radius: var(--radius-5);
  transition: var(--transition-2);
}

.service-card:is(:hover, :focus-within) .card-btn {
  background-color: #15504e;
  color: white !important;
}
.page-service-card .card-icon {
  color:#15504e !important;
  font-size: 70px;
  line-height: 1;
}

.page-service-card .card-title {
  margin-block: 15px 12px;
  transition: var(--transition-1);
}

.page-service-card .card-title:is(:hover, :focus) {
  color: #15504e;
}

.service-card .card-text {
  margin-block-end: 30px;
  color: #15504e !important;
}

.page-service-card .card-btn {
  color: #15504e !important;
  background-color: black;
  max-width: max-content;
  margin-inline: auto;
  font-size: 20px;
  padding: 15px;
  box-shadow: var(--shadow-2);
  border-radius: var(--radius-5);
  transition: var(--transition-2);
}

.page-service-card:is(:hover, :focus-within) .card-btn {
  background-color: #15504e;
  color: white !important;
}







/*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/

/**
   * responsive for large than 575px screen
   */

@media (min-width: 575px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-40: 6rem;

  }



  /**
     * REUSED STYLE
     */

  .container,
  .header-top {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }

  .btn {
    padding: 13px 40px;
  }

  .h2 {
    --fs-30: 3.5rem;
  }



}





/**
   * responsive for large than 768px screen
   */

@media (min-width: 768px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-40: 8rem;

  }



  /**
     * REUSED STYLE
     */

  .container {
    max-width: 720px;
  }

  .h2 {
    --fs-30: 4rem;
  }

  .section-text {
    max-width: 50ch;
    margin-inline: auto;
  }




  /**
     * SERVICE
     */

  .service .grid-list {
    grid-template-columns: 1fr 1fr;
  }


}





/**
   * responsive for large than 992px screen
   */

@media (min-width: 992px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-40: 10rem;

  }



  /**
     * REUSED STYLE
     */

  .container {
    max-width: 960px;
  }




  /**
     * SERVICE
     */

  .service .grid-list {
    grid-template-columns: repeat(3, 1fr);
  }




}





/**
   * responsive for large than 1200px screen
   */

@media (min-width: 1200px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-40: 11rem;

    /**
       * spacing
       */

    --section-padding: 120px;

  }



  /**
     * REUSED STYLE
     */

  .container {
    max-width: 1200px;
  }





}