.section { padding-block: var(--section-padding); }

.h2 { line-height: 1.1; }

.h2{ color: #15504e; }

.h2 { font-size: var(--fs-2); }

.section-title {
    text-align: center;
    margin-block-end: 30px;
  }
  
  .section-title .span {
    display: inline;
    color: #15504e;
  }
  .has-scrollbar {
    display: flex;
    align-items: center;
    gap: 30px;
    overflow-x: auto;
    scroll-snap-type: inline mandatory;
    padding-block-end: 20px;
    margin-block-end: -20px;
  }

  .category{
    background-color: white !important;
  }

  .scrollbar-item {
    min-width: 100%;
    scroll-snap-align: start;
  }

  .has-scrollbar::-webkit-scrollbar { height: 10px; }

.has-scrollbar::-webkit-scrollbar-track {
  outline: 2px solid var(--portland-orange);
  border-radius: var(--radius-10);
}

.has-scrollbar::-webkit-scrollbar-thumb {
  border-radius: var(--radius-10);
  background-color: var(--portland-orange);
  border: 2px solid var(--white);
}

.has-scrollbar::-webkit-scrollbar-button { width: calc(25% - 30px); }

.img-holder {
    aspect-ratio: var(--width) / var(--height);
    background-color: var(--light-gray);
    overflow: hidden;
  }
  
.category-card .card-banner { border-radius: var(--radius-10); }

.category-card .img-cover { transition: var(--transition-2); }

.category-card:is(:hover, :focus-within) .img-cover { transform: scale(1.08); }

.category-card .card-title {
  margin-block-start: 15px;
  text-align: center;
  transition: var(--transition-1);
}

.category-card .card-title:is(:hover, :focus) { color: var(--portland-orange); }


@media (min-width: 575px) {
    .scrollbar-item { min-width: calc(50% - 15px); }
}

@media (min-width: 992px) {


    /**
     * CATEGORY
     */
  
    .category-card .h3 { --fs-3: 2rem; }

}