

:root {

    /**
     * colors
     */
  
    --rich-black-fogra-39_50: hsla(0, 0%, 5%, 0.5);
    --rich-black-fogra-39: hsl(0, 0%, 5%);
    --indian-yellow_10: hsla(36, 61%, 58%, 0.1);
    --indian-yellow: hsl(36, 61%, 58%);
    --harvest-gold: hsl(36, 66%, 53%);
    --eerie-black-1: hsl(0, 0%, 14%);
    --eerie-black-2: hsl(0, 0%, 12%);
    --eerie-black-2_85: hsla(0, 0%, 12%, 0.85);
    --eerie-black-3: hsl(0, 0%, 8%);
    --sonic-silver: hsl(0, 0%, 44%);
    --davys-gray: hsl(210, 9%, 31%);
    --light-gray: hsl(0, 0%, 80%);
    --platinum: hsl(0, 0%, 91%);
    --black_30: hsla(0, 0%, 0%, 0.3);
    --white_10: hsla(0, 0%, 100%, 0.1);
    --white_30: hsla(0, 0%, 100%, 0.3 );
    --white_50: hsla(0, 0%, 100%, 0.5);
    --white: hsl(0, 0%, 100%);
    --jet: hsl(0, 0%, 21%);
  
    /**
     * typography
     */
  
    --ff-oswald : 'Oswald', sans-serif;
    --ff-rubik: 'Rubik', sans-serif;
  
    --fs-40: 4rem;
    --fs-30: 3rem;
    --fs-24: 2.4rem;
    --fs-18: 1.8rem;
    --fs-14: 1.4rem;
    --fs-13: 1.3rem;
  
    --fw-300: 300;
    --fw-500: 500;
    --fw-600: 600;
    --fw-700: 700;
  
    /**
     * spacing
     */
  
    --section-padding: 80px;
  
    /**
     * shadow
     */
  
    --shadow-1: 10px 0 60px hsla(0, 0%, 15%, 0.07);
    --shadow-2: 10px 0 60px hsla(0, 0%, 15%, 0.1);
  
    /**
     * radius
     */
  
    --radius-5: 5px;
    --radius-8: 8px;
  
    /**
     * transition
     */
  
    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);
  
  }
  
  
  
  
  
  /*-----------------------------------*\
    #RESET
  \*-----------------------------------*/
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  li { list-style: none; }
  
  a,
  img,
  span,
  data,
  input,
  select,
  button,
  textarea,
  ion-icon { display: block; }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  img { height: auto; }
  
  input,
  select,
  button,
  textarea {
    background: none;
    border: none;
    font: inherit;
  }
  
  input,
  select,
  textarea { width: 100%; }
  
  button { cursor: pointer; }
  
  ion-icon { pointer-events: none; }
  
  address { font-style: normal; }
  
  html {
    font-family: var(--ff-rubik);
    font-size: 10px;
    scroll-behavior: smooth;
  }
  
  body {
    background-color: var(--white);
    color: var(--sonic-silver);
    font-size: 1.6rem;
    line-height: 2;
  }
  
  :focus-visible { outline-offset: 4px; }
  
  ::-webkit-scrollbar { width: 10px; }
  
  ::-webkit-scrollbar-track { background-color: hsl(0, 0%, 98%); }
  
  ::-webkit-scrollbar-thumb { background-color: hsl(0, 0%, 80%); }
  
  ::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 70%); }
  
  
  
  
  
  /*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/
  
  .container { padding-inline: 15px; }
  
  .section { padding-block: var(--section-padding); }
  
  .has-before,
  .has-after {
    position: relative;
    z-index: 1;
  }
  
  .has-before::before,
  .has-after::after {
    position: absolute;
    content: "";
  }
  
  .has-bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .h1,
  .h2,
  .h3 {
    font-family: var(--ff-oswald);
    line-height: 1.3;
  }
  
  .h1,
  .h2 { text-transform: uppercase; }
  
  .h1,
  .h3 { font-weight: var(--fw-600); }
  
  .h1 {
    color: var(--white);
    font-size: var(--fs-40);
  }
  
  .h2,
  .h3 { color: var(--eerie-black-1); }
  
  .h2 { font-size: var(--fs-30); }
  
  .h3 { font-size: var(--fs-24); }
  
  .btn {
    color: var(--white);
    background-color: #15504e;
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: max-content;
    padding: 10px 25px;
    font-family: var(--ff-oswald);
    font-size: var(--fs-14);
    font-weight: var(--fw-600);
    text-transform: uppercase;
    border-radius: var(--radius-5);
    overflow: hidden;
  }
  
  
  

  .text-center { text-align: center; }
  
  .grid-list {
    display: grid;
    gap: 30px;
  }
  
  .img-holder {
    aspect-ratio: var(--width) / var(--height);
    background-color: var(--light-gray);
    overflow: hidden;
  }
  
  .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  
  
  
  
  
  
  /*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/
  
  .footer {
    background-color: #050608;
    padding-block-end: 30px;
  }
  
  .footer-top { margin-block-end: 65px; }
  
  .footer-brand {
    border: 1px solid var(--white_10);
    margin-block-end: 70px;
  }
  
  .footer .logo {
    text-align: center;
    padding: 25px;
    border-block-end: 1px solid var(--white_10);
    width: 30%;
  }

  .footer .logo img{
    width: 100%;
  }
  
  .footer .input-wrapper {
    background-color: var(--white);
    position: relative;
    margin: 25px;
    padding: 15px;
  }
  
  .footer .input-wrapper:focus-within { outline: 3px solid var(--white_30); }
  
  .footer .email-field {
    text-align: center;
    margin-block-end: 15px;
    color: inherit;
    outline: none;
  }
  
  .footer .btn {
    max-width: 100%;
    width: 100%;
    justify-content: center;
    background-color: #15504e !important;
  }
  
  .footer-link-box {
    display: grid;
    gap: 50px;
  }
  
  .footer-list-title {
    color: var(--white);
    font-family: var(--ff-oswald);
    font-size: var(--fs-18);
    font-weight: var(--fw-600);
    text-transform: uppercase;
    margin-block-end: 30px;
  }
  
  .footer-link::before {
    position: static;
    padding: 2.5px;
    background-color: var(--white_30);
    display: block;
    border-radius: 50%;
    transition: var(--transition-1);
  }
  
  .footer-link,
  .footer-list-item {
    display: flex;
    align-items: center;
  }
  
  .footer-link {
    color: var(--white_50);
    gap: 10px;
    margin-block-start: 8px;
    transition: var(--transition-1);
  }
  
  .footer-link:is(:hover, :focus) { color: var(--white); }
  
  .footer-link:is(:hover, :focus)::before { background-color: var(--white); }
  

  .footer-list-item {
    align-items: flex-start;
    gap: 10px;
    margin-block-start: 10px;
  }
  
  .footer-list-item ion-icon {
    color: #15504e;
    font-size: 18px;
    flex-shrink: 0;
    --ionicon-stroke-width: 50px;
    margin-block: 7px;
  }
  
  .contact-link {
    color: var(--white_50);
    transition: var(--transition-1);
  }
  
  a.contact-link:is(:hover, :focus) { color: var(--white); }
  
  .footer-bottom {
    background-color: var(--eerie-black-3);
    text-align: center;
    padding: 15px;
    color: white !important;
  }
  .footer-copyright {
    text-align: center;
    padding: 15px;
    color: white !important;
  }
  
  .copyright-link {
    display: inline-block;
    color: #15504e;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #BACK TO TOP
  \*-----------------------------------*/
  
  .back-top-btn {
    position: fixed;
    bottom: 10px;
    right: 20px;
    background-color: var(--harvest-gold);
    color: white !important;
    font-size: 20px;
    padding: 10px;
    border-radius: var(--radius-5);
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition-1);
  }
  
  .back-top-btn.active {
    opacity: 1;
    visibility: visible;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/
  
  /**
   * responsive for large than 575px screen
   */
  
  @media (min-width: 575px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-40: 6rem;
  
    }
  
  
  
    /**
     * FOOTER
     */
  
    .footer .email-field {
      margin-block-end: 0;
      text-align: left;
      font-size: var(--fs-18);
      padding: 10px 15px;
      padding-inline-end: 210px;
    }
  
    .footer .btn {
      position: absolute;
      top: 15px;
      right: 15px;
      bottom: 15px;
      width: max-content;
      transition: none !important;
    }

    .footer .btn:hover {
      position: absolute;
      top: 15px;
      right: 15px;
      bottom: 15px;
      width: max-content;
      color:#15504e !important;
      background-color: #fff !important;
    }

    .footer .btn .span:hover {
      
      color:#15504e !important;
      background-color: #fff !important;
    }
  
    .footer-link-box { grid-template-columns: 1fr 1fr; }
  
    .footer-list:is(:nth-child(3), :nth-child(4)) { grid-column: 1 / 3; }
  
  
  }
  
  
  
  
  
  /**
   * responsive for large than 768px screen
   */
  
  @media (min-width: 768px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-40: 8rem;
  
    }
  
  
  
    /**
     * FOOTER
     */
  
    .footer-list:is(:nth-child(3), :nth-child(4)) { grid-column: auto; }
  
  }
  
  
  
  
  
  /**
   * responsive for large than 992px screen
   */
  
  @media (min-width: 992px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-40: 10rem;
  
    }
  
  
  
    
    /**
     * FOOTER
     */
  
    .footer-brand {
      display: flex;
      align-items: center;
    }
  
    .footer .logo {
      padding: 60px 70px;
      border-block-end: none;
      border-inline-end: 1px solid var(--white_10);
    }
  
    .footer .input-wrapper {
      flex-grow: 1;
      margin-inline: 70px;
    }
  
  }
  
  
  
  
  
  /**
   * responsive for large than 1200px screen
   */
  
  @media (min-width: 1200px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-40: 11rem;
  
      /**
       * spacing
       */
  
      --section-padding: 120px;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 1200px; }
  
  
  
    
    /**
     * FOOTER
     */
  
    .footer-link-box { grid-template-columns: 1fr 1fr 1fr ; }
  
  
  
    /**
     * BACK TO TOP
     */
  
    .back-top-btn { padding: 15px; }
  
  }